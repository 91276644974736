var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.isRegistrationWizard)?_c('ul',{staticClass:"biz-breadcrumb"},[_c('li',[_c('router-link',{attrs:{"to":"/supplier/manage"}},[_vm._v(_vm._s(_vm.$t('SYSTEM_MANAGEMENT.TITLE')))])],1),_c('li',[_c('router-link',{attrs:{"to":"/supplier/manage/signer-info"}},[_vm._v(_vm._s(_vm.$t('SIGNER_INFO.TITLE')))])],1),_c('li',[_c('a',[_vm._v(_vm._s(_vm.$t('COMMON.EDIT')))])])]):_vm._e(),_c('div',{staticClass:"page"},[(!_vm.isRegistrationWizard)?_c('div',{staticClass:"page-title"},[_c('h1',[_vm._v(_vm._s(_vm.$t('SIGNER_INFO.TITLE')))]),_c('p',[_vm._v(_vm._s(_vm.$t('SIGNER_INFO.DESCRIPTION')))])]):_vm._e(),_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.save)}}},[_c('div',{staticClass:"card biz-primary mt-4"},[_c('div',{staticClass:"card-header"},[_vm._v(" "+_vm._s(_vm.$t('SIGNER_INFO.HEADER_SIGNER'))+" ")]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"biz-form-group pb-4 seperated"},[_c('label',{staticClass:"biz-form-label required"},[_vm._v(_vm._s(_vm.$t('SIGNER_INFO.SIGNER')))]),_c('div',{staticClass:"biz-form-body"},[_c('div',{staticClass:"biz-input-group"},[_c('label',[_vm._v(_vm._s(_vm.$t('COMPANY_INFO.COMPANY_NAME')))]),_c('br'),_vm._v(" "+_vm._s(_vm.companyName)+" ")]),_c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"col col-3"},[_c('b-form-group',{staticClass:"biz-input-group mb-0",attrs:{"label":_vm.$t('SIGNER_INFO.POSITION')}},[_c('validation-provider',{attrs:{"name":_vm.$t('SIGNER_INFO.POSITION'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","placeholder":_vm.$t('SIGNER_INFO.POSITION_PLACEHOLDER')},model:{value:(_vm.signer.jobTitle),callback:function ($$v) {_vm.$set(_vm.signer, "jobTitle", $$v)},expression:"signer.jobTitle"}}),_c('span',{staticClass:"error-field text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-12"},[_c('b-form-group',{staticClass:"biz-input-group mt-3",attrs:{"label":_vm.$t('COMMON.NAME_KANJI')}},[_c('b-input-group',[_c('validation-provider',{staticClass:"col-2 pl-0",attrs:{"vid":"signerLastNameKanji","name":_vm.$t('COMMON.LAST_NAME_KANJI'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","placeholder":_vm.$t('COMMON.LAST_NAME_KANJI_PLACEHOLDER')},model:{value:(_vm.signer.lastName),callback:function ($$v) {_vm.$set(_vm.signer, "lastName", $$v)},expression:"signer.lastName"}}),_c('span',{staticClass:"error-field text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{staticClass:"col-2 pl-1",attrs:{"vid":"signerFirstNameKanzi","name":_vm.$t('COMMON.FIRST_NAME_KANJI'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","placeholder":_vm.$t('COMMON.FIRST_NAME_KANJI_PLACEHOLDER')},model:{value:(_vm.signer.firstName),callback:function ($$v) {_vm.$set(_vm.signer, "firstName", $$v)},expression:"signer.firstName"}}),_c('span',{staticClass:"error-field text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('div',{staticClass:"col col-12"},[_c('b-form-group',{staticClass:"biz-input-group",attrs:{"label":_vm.$t('COMMON.NAME_KANA')}},[_c('b-input-group',[_c('validation-provider',{staticClass:"col-2 pl-0",attrs:{"vid":"signerLastNameKana","name":_vm.$t('COMMON.LAST_NAME_KANA'),"rules":{required: true, katakanaOnly: true  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"b-popover",rawName:"v-b-popover.focus.top",value:(_vm.$t('COMMON.LAST_NAME_KANA_DESC')),expression:"$t('COMMON.LAST_NAME_KANA_DESC')",modifiers:{"focus":true,"top":true}}],attrs:{"type":"text","placeholder":_vm.$t('COMMON.LAST_NAME_KANA_PLACEHOLDER')},model:{value:(_vm.signer.lastNameKana),callback:function ($$v) {_vm.$set(_vm.signer, "lastNameKana", $$v)},expression:"signer.lastNameKana"}}),_c('span',{staticClass:"error-field text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{staticClass:"col-2 pl-1",attrs:{"vid":"signerFirstNameKana","name":_vm.$t('COMMON.FIRST_NAME_KANA'),"rules":{required: true, katakanaOnly: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"b-popover",rawName:"v-b-popover.focus.top",value:(_vm.$t('COMMON.FIRST_NAME_KANA_DESC')),expression:"$t('COMMON.FIRST_NAME_KANA_DESC')",modifiers:{"focus":true,"top":true}}],attrs:{"type":"text","placeholder":_vm.$t('COMMON.FIRST_NAME_KANA_PLACEHOLDER')},model:{value:(_vm.signer.firstNameKana),callback:function ($$v) {_vm.$set(_vm.signer, "firstNameKana", $$v)},expression:"signer.firstNameKana"}}),_c('span',{staticClass:"error-field text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('div',{staticClass:"col col-12"},[_c('b-form-group',{staticClass:"biz-input-group",attrs:{"label":_vm.$t('COMMON.NAME_EN')}},[_c('b-input-group',[_c('validation-provider',{staticClass:"col-2 pl-0",attrs:{"vid":"signerLastNameEn","name":_vm.$t('COMMON.LAST_NAME_EN'),"rules":{required: true, romajiOnly: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"b-popover",rawName:"v-b-popover.focus.top",value:(_vm.$t('COMMON.LAST_NAME_EN_DESC')),expression:"$t('COMMON.LAST_NAME_EN_DESC')",modifiers:{"focus":true,"top":true}}],attrs:{"type":"text","placeholder":_vm.$t('COMMON.LAST_NAME_EN_PLACEHOLDER')},model:{value:(_vm.signer.lastNameEn),callback:function ($$v) {_vm.$set(_vm.signer, "lastNameEn", $$v)},expression:"signer.lastNameEn"}}),_c('span',{staticClass:"error-field text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{staticClass:"col-2 pl-1",attrs:{"vid":"signerFirstNameEn","name":_vm.$t('COMMON.FIRST_NAME_EN'),"rules":{required: true, romajiOnly: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"b-popover",rawName:"v-b-popover.focus.top",value:(_vm.$t('COMMON.FIRST_NAME_EN_DESC')),expression:"$t('COMMON.FIRST_NAME_EN_DESC')",modifiers:{"focus":true,"top":true}}],attrs:{"type":"text","placeholder":_vm.$t('COMMON.FIRST_NAME_EN_PLACEHOLDER')},model:{value:(_vm.signer.firstNameEn),callback:function ($$v) {_vm.$set(_vm.signer, "firstNameEn", $$v)},expression:"signer.firstNameEn"}}),_c('span',{staticClass:"error-field text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-3"},[_c('validation-provider',{attrs:{"name":_vm.$t('COMMON.PHONENUMBER'),"vid":"signerPhone","rules":{required: true,phonenumber:true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"biz-input-group mb-0",attrs:{"label":_vm.$t('COMMON.PHONENUMBER')}},[_c('b-form-input',{directives:[{name:"b-popover",rawName:"v-b-popover.focus.top",value:(_vm.$t('COMMON.PHONENUMBER_DESC')),expression:"$t('COMMON.PHONENUMBER_DESC')",modifiers:{"focus":true,"top":true}}],attrs:{"type":"text","placeholder":_vm.$t('COMMON.PHONENUMBER_PLACEHOLDER')},model:{value:(_vm.signer.mobilePhone),callback:function ($$v) {_vm.$set(_vm.signer, "mobilePhone", $$v)},expression:"signer.mobilePhone"}})],1),_c('span',{staticClass:"error-field text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col col-3"},[_c('validation-provider',{attrs:{"name":_vm.$t('COMMON.EMAIL_ADDRESS'),"vid":"signerEmail","rules":"required|email|notEqual:@substituteEmail"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"biz-input-group mb-0",attrs:{"label":_vm.$t('COMMON.EMAIL_ADDRESS'),"disabled":_vm.originalSignerEmail.length > 0}},[_c('b-form-input',{attrs:{"type":"text","placeholder":_vm.$t('COMMON.EMAIL_ADDRESS_PLACEHOLDER')},model:{value:(_vm.signer.email),callback:function ($$v) {_vm.$set(_vm.signer, "email", $$v)},expression:"signer.email"}}),(_vm.originalSignerEmail.length > 0)?_c('router-link',{staticClass:"form-text",attrs:{"to":"/supplier/manage/user-info/edit"}},[_vm._v(_vm._s(_vm.$t('SIGNER_INFO.CHANGE_EMAIL_LINK')))]):_vm._e()],1),_c('span',{staticClass:"error-field text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])])]),_c('div',{staticClass:"biz-form-group pb-4 mt-4"},[_c('label',{staticClass:"biz-form-label",class:_vm.needSubstitute?'required':'optional'},[_vm._v(_vm._s(_vm.$t('SIGNER_INFO.SUBSTITUTE')))]),_c('div',{staticClass:"biz-form-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-3"},[_c('b-form-group',{staticClass:"biz-input-group",attrs:{"label":_vm.$t('SIGNER_INFO.POSITION')}},[_c('b-form-input',{attrs:{"type":"text","placeholder":_vm.$t('SIGNER_INFO.POSITION_PLACEHOLDER')},model:{value:(_vm.substitute.jobTitle),callback:function ($$v) {_vm.$set(_vm.substitute, "jobTitle", $$v)},expression:"substitute.jobTitle"}})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-12"},[_c('b-form-group',{staticClass:"biz-input-group",attrs:{"label":_vm.$t('COMMON.NAME_KANJI')}},[_c('b-input-group',[_c('validation-provider',{staticClass:"col-2 pl-0",attrs:{"name":_vm.$t('COMMON.LAST_NAME_KANJI'),"rules":{required:_vm.needSubstitute}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","placeholder":_vm.$t('COMMON.LAST_NAME_KANJI_PLACEHOLDER')},model:{value:(_vm.substitute.lastName),callback:function ($$v) {_vm.$set(_vm.substitute, "lastName", $$v)},expression:"substitute.lastName"}}),_c('span',{staticClass:"error-field text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{staticClass:"col-2 pl-1",attrs:{"name":_vm.$t('COMMON.FIRST_NAME_KANJI'),"rules":{required:_vm.needSubstitute}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","placeholder":_vm.$t('COMMON.FIRST_NAME_KANJI_PLACEHOLDER')},model:{value:(_vm.substitute.firstName),callback:function ($$v) {_vm.$set(_vm.substitute, "firstName", $$v)},expression:"substitute.firstName"}}),_c('span',{staticClass:"error-field text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('div',{staticClass:"col col-12"},[_c('b-form-group',{staticClass:"biz-input-group",attrs:{"label":_vm.$t('COMMON.NAME_KANA')}},[_c('b-input-group',[_c('validation-provider',{staticClass:"col-2 pl-0",attrs:{"name":_vm.$t('COMMON.LAST_NAME_KANA'),"rules":{required: _vm.needSubstitute, katakanaOnly: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"b-popover",rawName:"v-b-popover.focus.top",value:(_vm.$t('COMMON.LAST_NAME_KANA_DESC')),expression:"$t('COMMON.LAST_NAME_KANA_DESC')",modifiers:{"focus":true,"top":true}}],attrs:{"type":"text","placeholder":_vm.$t('COMMON.LAST_NAME_KANA_PLACEHOLDER')},model:{value:(_vm.substitute.lastNameKana),callback:function ($$v) {_vm.$set(_vm.substitute, "lastNameKana", $$v)},expression:"substitute.lastNameKana"}}),_c('span',{staticClass:"error-field text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{staticClass:"col-2 pl-1",attrs:{"name":_vm.$t('COMMON.FIRST_NAME_KANA'),"rules":{required: _vm.needSubstitute, katakanaOnly: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"b-popover",rawName:"v-b-popover.focus.top",value:(_vm.$t('COMMON.FIRST_NAME_KANA_DESC')),expression:"$t('COMMON.FIRST_NAME_KANA_DESC')",modifiers:{"focus":true,"top":true}}],attrs:{"type":"text","placeholder":_vm.$t('COMMON.FIRST_NAME_KANA_PLACEHOLDER')},model:{value:(_vm.substitute.firstNameKana),callback:function ($$v) {_vm.$set(_vm.substitute, "firstNameKana", $$v)},expression:"substitute.firstNameKana"}}),_c('span',{staticClass:"error-field text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('div',{staticClass:"col col-12"},[_c('b-form-group',{staticClass:"biz-input-group",attrs:{"label":_vm.$t('COMMON.NAME_EN')}},[_c('b-input-group',[_c('validation-provider',{staticClass:"col-2 pl-0",attrs:{"name":_vm.$t('COMMON.LAST_NAME_EN'),"rules":{required: _vm.needSubstitute, romajiOnly:true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"b-popover",rawName:"v-b-popover.focus.top",value:(_vm.$t('COMMON.LAST_NAME_EN_DESC')),expression:"$t('COMMON.LAST_NAME_EN_DESC')",modifiers:{"focus":true,"top":true}}],attrs:{"type":"text","placeholder":_vm.$t('COMMON.LAST_NAME_EN_PLACEHOLDER')},model:{value:(_vm.substitute.lastNameEn),callback:function ($$v) {_vm.$set(_vm.substitute, "lastNameEn", $$v)},expression:"substitute.lastNameEn"}}),_c('span',{staticClass:"error-field text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{staticClass:"col-2 pl-1",attrs:{"name":_vm.$t('COMMON.FIRST_NAME_EN'),"rules":{required: _vm.needSubstitute, romajiOnly:true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"b-popover",rawName:"v-b-popover.focus.top",value:(_vm.$t('COMMON.FIRST_NAME_EN_DESC')),expression:"$t('COMMON.FIRST_NAME_EN_DESC')",modifiers:{"focus":true,"top":true}}],attrs:{"type":"text","placeholder":_vm.$t('COMMON.FIRST_NAME_EN_PLACEHOLDER')},model:{value:(_vm.substitute.firstNameEn),callback:function ($$v) {_vm.$set(_vm.substitute, "firstNameEn", $$v)},expression:"substitute.firstNameEn"}}),_c('span',{staticClass:"error-field text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-3"},[_c('validation-provider',{staticClass:"col-2 pl-0",attrs:{"name":_vm.$t('COMMON.PHONENUMBER'),"rules":{required: _vm.needSubstitute,phonenumber:true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"biz-input-group mb-0",attrs:{"label":_vm.$t('COMMON.PHONENUMBER')}},[_c('b-form-input',{directives:[{name:"b-popover",rawName:"v-b-popover.focus.top",value:(_vm.$t('COMMON.PHONENUMBER_DESC')),expression:"$t('COMMON.PHONENUMBER_DESC')",modifiers:{"focus":true,"top":true}}],attrs:{"type":"text","placeholder":_vm.$t('COMMON.PHONENUMBER_PLACEHOLDER')},model:{value:(_vm.substitute.mobilePhone),callback:function ($$v) {_vm.$set(_vm.substitute, "mobilePhone", $$v)},expression:"substitute.mobilePhone"}})],1),_c('span',{staticClass:"error-field text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-3"},[_c('validation-provider',{attrs:{"vid":"substituteEmail","name":_vm.$t('COMMON.EMAIL_ADDRESS'),"rules":"email|notEqual:@signerEmail"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"biz-input-group mb-0",attrs:{"label":_vm.$t('COMMON.EMAIL_ADDRESS'),"disabled":_vm.originalSubstituteEmail.length > 0}},[_c('b-form-input',{attrs:{"type":"text","placeholder":_vm.$t('COMMON.EMAIL_ADDRESS_PLACEHOLDER')},model:{value:(_vm.substitute.email),callback:function ($$v) {_vm.$set(_vm.substitute, "email", $$v)},expression:"substitute.email"}}),(_vm.originalSubstituteEmail.length > 0)?_c('router-link',{staticClass:"form-text",attrs:{"to":"/supplier/manage/user-info/edit"}},[_vm._v(_vm._s(_vm.$t('SIGNER_INFO.CHANGE_EMAIL_LINK')))]):_vm._e()],1),_c('span',{staticClass:"error-field text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])])])])]),_c('div',{staticClass:"text-center mt-4"},[_c('j-button',{attrs:{"buttonType":"submit","size":"lg","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.isRegistrationWizard?_vm.$t('COMMON.NEXT'):_vm.$t('COMMON.SAVE'))+" ")]),_c('br'),(!_vm.isRegistrationWizard)?_c('j-button',{staticClass:"mt-2",attrs:{"variant":"light","buttonType":"button","to":"/supplier/manage/signer-info"}},[_c('font-awesome-icon',{attrs:{"icon":"arrow-left"}}),_vm._v(" "+_vm._s(_vm.$t('COMMON.CANCEL'))+" ")],1):_vm._e()],1)])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }