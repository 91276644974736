<template>
  <div>
    <ul class="biz-breadcrumb" v-if="!isRegistrationWizard">
      <li><router-link to="/supplier/manage">{{$t('SYSTEM_MANAGEMENT.TITLE')}}</router-link></li>
      <li><router-link to="/supplier/manage/signer-info">{{$t('SIGNER_INFO.TITLE')}}</router-link></li>
      <li><a>{{$t('COMMON.EDIT')}}</a></li>
    </ul>
    <div class="page">
      <div class="page-title" v-if="!isRegistrationWizard">
        <h1>{{$t('SIGNER_INFO.TITLE')}}</h1>
        <p>{{$t('SIGNER_INFO.DESCRIPTION')}}</p>
      </div>

      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <form @submit.prevent="handleSubmit(save)">
          <div class="card biz-primary mt-4">
            <div class="card-header">
              {{$t('SIGNER_INFO.HEADER_SIGNER')}}
            </div>
            <div class="card-body">
              <div class="biz-form-group pb-4 seperated">
                <label class="biz-form-label required">{{$t('SIGNER_INFO.SIGNER')}}</label>
                  <div class="biz-form-body">
                    <div class="biz-input-group">
                      <label>{{$t('COMPANY_INFO.COMPANY_NAME')}}</label><br/>
                      {{companyName}}
                    </div>
                    <div class="row mt-2">
                      <div class="col col-3">
                        <b-form-group class="biz-input-group mb-0" :label="$t('SIGNER_INFO.POSITION')">
                          <validation-provider :name="$t('SIGNER_INFO.POSITION')" rules="required" v-slot="{ errors }">
                            <b-form-input type="text" v-model="signer.jobTitle" :placeholder="$t('SIGNER_INFO.POSITION_PLACEHOLDER')" />
                            <span class="error-field text-danger">{{ errors[0] }}</span>
                          </validation-provider>
                        </b-form-group>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col col-12">
                        <b-form-group class="biz-input-group mt-3" :label="$t('COMMON.NAME_KANJI')">
                          <b-input-group>
                            <validation-provider class="col-2 pl-0" vid="signerLastNameKanji" :name="$t('COMMON.LAST_NAME_KANJI')" rules="required" v-slot="{ errors }">
                              <b-form-input type="text" v-model="signer.lastName" :placeholder="$t('COMMON.LAST_NAME_KANJI_PLACEHOLDER')"/>
                              <span class="error-field text-danger">{{ errors[0] }}</span>
                            </validation-provider>
                            <validation-provider class="col-2 pl-1" vid="signerFirstNameKanzi" :name="$t('COMMON.FIRST_NAME_KANJI')" rules="required" v-slot="{ errors }">
                              <b-form-input type="text" v-model="signer.firstName" :placeholder="$t('COMMON.FIRST_NAME_KANJI_PLACEHOLDER')"/>
                              <span class="error-field text-danger">{{ errors[0] }}</span>
                            </validation-provider>
                          </b-input-group>
                        </b-form-group>
                      </div>
                      <div class="col col-12">
                        <b-form-group class="biz-input-group" :label="$t('COMMON.NAME_KANA')">
                          <b-input-group>
                            <validation-provider class="col-2 pl-0" vid="signerLastNameKana" :name="$t('COMMON.LAST_NAME_KANA')" :rules="{required: true, katakanaOnly: true  }" v-slot="{ errors }">
                              <b-form-input 
                                type="text" 
                                v-b-popover.focus.top="$t('COMMON.LAST_NAME_KANA_DESC')" 
                                v-model="signer.lastNameKana" 
                                :placeholder="$t('COMMON.LAST_NAME_KANA_PLACEHOLDER')"/>
                              <span class="error-field text-danger">{{ errors[0] }}</span>
                            </validation-provider>
                            <validation-provider class="col-2 pl-1" vid="signerFirstNameKana" :name="$t('COMMON.FIRST_NAME_KANA')" :rules="{required: true, katakanaOnly: true }" v-slot="{ errors }">
                              <b-form-input 
                                type="text" 
                                v-b-popover.focus.top="$t('COMMON.FIRST_NAME_KANA_DESC')" 
                                v-model="signer.firstNameKana" :placeholder="$t('COMMON.FIRST_NAME_KANA_PLACEHOLDER')"/>
                              <span class="error-field text-danger">{{ errors[0] }}</span>
                            </validation-provider>
                          </b-input-group>
                        </b-form-group>
                      </div>
                      <div class="col col-12">
                        <b-form-group class="biz-input-group" :label="$t('COMMON.NAME_EN')">
                          <b-input-group>
                            <validation-provider class="col-2 pl-0" vid="signerLastNameEn" :name="$t('COMMON.LAST_NAME_EN')" :rules="{required: true, romajiOnly: true }" v-slot="{ errors }">
                              <b-form-input 
                                type="text" 
                                v-b-popover.focus.top="$t('COMMON.LAST_NAME_EN_DESC')" 
                                v-model="signer.lastNameEn" :placeholder="$t('COMMON.LAST_NAME_EN_PLACEHOLDER')"/>
                              <span class="error-field text-danger">{{ errors[0] }}</span>
                            </validation-provider>
                            <validation-provider class="col-2 pl-1" vid="signerFirstNameEn" :name="$t('COMMON.FIRST_NAME_EN')" :rules="{required: true, romajiOnly: true }" v-slot="{ errors }">
                              <b-form-input 
                                type="text" 
                                v-b-popover.focus.top="$t('COMMON.FIRST_NAME_EN_DESC')" 
                                v-model="signer.firstNameEn" :placeholder="$t('COMMON.FIRST_NAME_EN_PLACEHOLDER')"/>
                              <span class="error-field text-danger">{{ errors[0] }}</span>
                            </validation-provider>
                          </b-input-group>
                        </b-form-group>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col col-3">
                        <validation-provider :name="$t('COMMON.PHONENUMBER')" vid="signerPhone" :rules="{required: true,phonenumber:true }" v-slot="{ errors }">
                          <b-form-group class="biz-input-group mb-0" :label="$t('COMMON.PHONENUMBER')">
                            <b-form-input 
                              type="text" 
                              v-b-popover.focus.top="$t('COMMON.PHONENUMBER_DESC')" 
                              v-model="signer.mobilePhone" :placeholder="$t('COMMON.PHONENUMBER_PLACEHOLDER')" />
                          </b-form-group>
                          <span class="error-field text-danger">{{ errors[0] }}</span>
                        </validation-provider>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col col-3">
                        <validation-provider :name="$t('COMMON.EMAIL_ADDRESS')" vid="signerEmail" rules="required|email|notEqual:@substituteEmail" v-slot="{ errors }">
                          <b-form-group class="biz-input-group mb-0" :label="$t('COMMON.EMAIL_ADDRESS')" :disabled="originalSignerEmail.length > 0">
                            <b-form-input type="text" v-model="signer.email" :placeholder="$t('COMMON.EMAIL_ADDRESS_PLACEHOLDER')" />
                            <router-link to="/supplier/manage/user-info/edit" class=" form-text" v-if="originalSignerEmail.length > 0">{{$t('SIGNER_INFO.CHANGE_EMAIL_LINK')}}</router-link>
                          </b-form-group>
                          <span class="error-field text-danger">{{ errors[0] }}</span>
                        </validation-provider>
                      </div>
                    </div>
                  </div>
              </div>
              <div class="biz-form-group pb-4 mt-4">
                <label class="biz-form-label" :class="needSubstitute?'required':'optional'">{{$t('SIGNER_INFO.SUBSTITUTE')}}</label>
                <div class="biz-form-body">
                  <div class="row">
                    <div class="col col-3">
                        <b-form-group class="biz-input-group" :label="$t('SIGNER_INFO.POSITION')">
                          <b-form-input type="text" v-model="substitute.jobTitle" :placeholder="$t('SIGNER_INFO.POSITION_PLACEHOLDER')" />
                        </b-form-group>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col col-12">
                      <b-form-group class="biz-input-group" :label="$t('COMMON.NAME_KANJI')">
                        <b-input-group>
                          <validation-provider class="col-2 pl-0" :name="$t('COMMON.LAST_NAME_KANJI')" :rules="{required:needSubstitute}" v-slot="{ errors }">
                            <b-form-input type="text" v-model="substitute.lastName" :placeholder="$t('COMMON.LAST_NAME_KANJI_PLACEHOLDER')"/>
                            <span class="error-field text-danger">{{ errors[0] }}</span>
                          </validation-provider>
                          <validation-provider class="col-2 pl-1" :name="$t('COMMON.FIRST_NAME_KANJI')" :rules="{required:needSubstitute}" v-slot="{ errors }">
                            <b-form-input type="text" v-model="substitute.firstName" :placeholder="$t('COMMON.FIRST_NAME_KANJI_PLACEHOLDER')"/>
                            <span class="error-field text-danger">{{ errors[0] }}</span>
                          </validation-provider>
                        </b-input-group>
                      </b-form-group>
                    </div>
                    <div class="col col-12">
                      <b-form-group class="biz-input-group" :label="$t('COMMON.NAME_KANA')">
                        <b-input-group>
                          <validation-provider class="col-2 pl-0" :name="$t('COMMON.LAST_NAME_KANA')" :rules="{required: needSubstitute, katakanaOnly: true }" v-slot="{ errors }">
                            <b-form-input 
                              type="text" 
                              v-b-popover.focus.top="$t('COMMON.LAST_NAME_KANA_DESC')" 
                              v-model="substitute.lastNameKana" :placeholder="$t('COMMON.LAST_NAME_KANA_PLACEHOLDER')"/>
                            <span class="error-field text-danger">{{ errors[0] }}</span>
                          </validation-provider>
                          <validation-provider class="col-2 pl-1" :name="$t('COMMON.FIRST_NAME_KANA')" :rules="{required: needSubstitute, katakanaOnly: true }" v-slot="{ errors }">
                            <b-form-input 
                              type="text" 
                              v-b-popover.focus.top="$t('COMMON.FIRST_NAME_KANA_DESC')" 
                              v-model="substitute.firstNameKana" :placeholder="$t('COMMON.FIRST_NAME_KANA_PLACEHOLDER')"/>
                            <span class="error-field text-danger">{{ errors[0] }}</span>
                          </validation-provider>
                        </b-input-group>
                      </b-form-group>
                    </div>
                    <div class="col col-12">
                      <b-form-group class="biz-input-group" :label="$t('COMMON.NAME_EN')">
                        <b-input-group>
                          <validation-provider class="col-2 pl-0" :name="$t('COMMON.LAST_NAME_EN')" :rules="{required: needSubstitute, romajiOnly:true }" v-slot="{ errors }">
                            <b-form-input 
                              type="text" 
                              v-b-popover.focus.top="$t('COMMON.LAST_NAME_EN_DESC')" 
                              v-model="substitute.lastNameEn" :placeholder="$t('COMMON.LAST_NAME_EN_PLACEHOLDER')"/>
                            <span class="error-field text-danger">{{ errors[0] }}</span>
                          </validation-provider>
                          <validation-provider class="col-2 pl-1"  :name="$t('COMMON.FIRST_NAME_EN')" :rules="{required: needSubstitute, romajiOnly:true }" v-slot="{ errors }">
                            <b-form-input
                              type="text" 
                              v-b-popover.focus.top="$t('COMMON.FIRST_NAME_EN_DESC')" 
                              v-model="substitute.firstNameEn" :placeholder="$t('COMMON.FIRST_NAME_EN_PLACEHOLDER')"/>
                            <span class="error-field text-danger">{{ errors[0] }}</span>
                          </validation-provider>
                        </b-input-group>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col col-3">
                      <validation-provider class="col-2 pl-0" :name="$t('COMMON.PHONENUMBER')" :rules="{required: needSubstitute,phonenumber:true }" v-slot="{ errors }">
                      <b-form-group class="biz-input-group mb-0" :label="$t('COMMON.PHONENUMBER')">
                        <b-form-input 
                          type="text" 
                          v-b-popover.focus.top="$t('COMMON.PHONENUMBER_DESC')" 
                          v-model="substitute.mobilePhone" :placeholder="$t('COMMON.PHONENUMBER_PLACEHOLDER')" />
                      </b-form-group>
                      <span class="error-field text-danger">{{ errors[0] }}</span>
                    </validation-provider>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col col-3">
                      <validation-provider vid="substituteEmail" :name="$t('COMMON.EMAIL_ADDRESS')" rules="email|notEqual:@signerEmail" v-slot="{ errors }">
                        <b-form-group class="biz-input-group mb-0" :label="$t('COMMON.EMAIL_ADDRESS')" :disabled="originalSubstituteEmail.length > 0">
                          <b-form-input type="text" v-model="substitute.email" :placeholder="$t('COMMON.EMAIL_ADDRESS_PLACEHOLDER')" />
                          <router-link to="/supplier/manage/user-info/edit" class=" form-text" v-if="originalSubstituteEmail.length > 0">{{$t('SIGNER_INFO.CHANGE_EMAIL_LINK')}}</router-link>
                        </b-form-group>
                        <span class="error-field text-danger">{{ errors[0] }}</span>
                      </validation-provider>
                    </div>
                  </div>
                
                </div>
              </div>
            </div>
          </div>
          <div class="text-center mt-4">
            <j-button buttonType="submit" size="lg" variant="primary">
              {{isRegistrationWizard?$t('COMMON.NEXT'):$t('COMMON.SAVE')}}
            </j-button>
            <br />
            <j-button variant="light" buttonType="button" class="mt-2" to="/supplier/manage/signer-info" v-if="!isRegistrationWizard">
              <font-awesome-icon icon="arrow-left"></font-awesome-icon>
              {{$t('COMMON.CANCEL')}}
            </j-button>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<style scoped lang="scss">
.error-field{
  font-size:0.8em;
}
</style>
<script>
  import CompanyInfo from '@/model/CompanyInfo.js';
  import UserInfo from '@/model/UserInfo.js';
  
  export default {
    props:['isRegistrationWizard'],
    components: {  },
    data: function(){
      return {
        signer: {},
        substitute: {},
        companyName: "",
        originalSigner: "",
        originalSignerEmail: "",
        originalSubstitute: "",
        originalSubstituteEmail: ""
      }      
    },
    computed:{
      needSubstitute: {
        get() {
          return false
        }
      },
    },
    created: async function(){
      try{
        let signers = (await UserInfo.getSigner(this.$store.state.user.companyCode)).data;
        let company = (await CompanyInfo.getCompanyBasicInfo(this.$store.state.user.companyCode)).data;
        this.signer = signers.signer || {};
        this.substitute = signers.substitute || {};
        this.originalSignerEmail = this.signer.email || "";
        this.originalSubstituteEmail = this.substitute.email || "";
        this.companyName = company.fullName;
      }catch(reason) {this.$errorMessage(undefined, {reason})}
    },
    methods: {
      save: async function(){
        try{
          this.substitute.isSubstitute = true
          let res = await UserInfo.checkUser(this.$store.state.user.companyCode, this.signer.email, this.signer.userCode);
          if(this.signer.userCode != null && res.data.message=="true"){
            this.$warningMessage(this.$t('COMMON.USER_DUPLICATE_WARNING'));return;
          }
          res = await UserInfo.checkUser(this.$store.state.user.companyCode, this.substitute.email, this.substitute.userCode);
          if(this.substitute.userCode != null && res.data.message=="true"){
            this.$warningMessage(this.$t('COMMON.USER_DUPLICATE_WARNING'));return;
          }
          
          await UserInfo.saveSigner(this.$store.state.user.companyCode, {
            signer: this.signer,
            substitute: this.substitute.firstName ? this.substitute : null
          });
          this.$successMessage(this.$t('SIGNER_INFO.SAVE_SUCCESS'));
          this.$emit('save');
          if(this.signer.email === this.$store.state.user.displayCode || this.substitute.email === this.$store.state.user.displayCode){
            if(!this.hasRole("SB20")){
              let commitRoles = this.$store.state.user.roles
              commitRoles.push({roleCode:"SB20", roleName:"署名・契約管理", checked:true})
              this.$store.commit("setRoles", commitRoles)
              this.$router.go(this.$router.currentRoute)
            }
          }
        }catch(reason){
          this.$errorMessage(undefined, {reason});
        }
      },   
    hasRole(roleCode){
      if(!this.$store.state.logged) return false;
      for(var i=0; i < this.$store.state.user.roles.length; i++){
        
        if(this.$store.state.user.roles[i].roleCode === roleCode){
          return true;
        }
      }
      return false;
    }
    }
    
  }
</script>